import { isNotNullOrUndefined } from "../../../shared/functions/validations";
import { formatInputText } from "./formats";
import { isSmartphone } from "../../../shared/functions/typeDevice";
import moment from "moment";

export const dateToFormat = (argDate) => {
  let date = argDate;
  if (argDate.seconds) {
    if (argDate.seconds > 0) {
      date = new Date(argDate.seconds * 1000);
    } else {
      date = new Date();
    }
  }
  if (typeof argDate === "string") {
    date = new Date(argDate);
  }
  if (argDate.seconds === 0) {
    date = new Date();
  }
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  return `0${month}-0${day}-${year}`;
};

export const dateToFormatWithHour = (argDate) => {
  let date = argDate;
  if (argDate.seconds) {
    date = argDate.toDate();
  }
  if (typeof argDate === "string") {
    date = new Date(argDate);
  }
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  return `0${month}-0${day}-${year}`;
};

export const dateToFormat2 = (argDate) => {
  let date = argDate;
  if (argDate.seconds) {
    date = argDate.toDate();
  }
  if (typeof argDate === "string") {
    date = new Date(argDate);
  }
  if (!date) {
    return "-";
  }
  if (!date.getTime) {
    return "-";
  }
  let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  let month =
    date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1;
  let year =
    date.getFullYear() < 10 ? "0" + date.getFullYear() : date.getFullYear();
  return day + "/" + month + "/" + year;
};

export const dateToFormat3 = (argDate) => {
  let date = argDate;
  if (argDate.seconds) {
    date = new Date(argDate.seconds * 1000);
  }
  if (typeof argDate === "string") {
    date = new Date(argDate);
  }
  if (!date) {
    return "-";
  }
  if (!date.getTime) {
    return "-";
  }
  let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  let month =
    date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1;
  let year =
    date.getFullYear() < 10 ? "0" + date.getFullYear() : date.getFullYear();
  return day + "/" + month + "/" + year;
};

export const dateHour = (argDate) => {
  let date = argDate;
  if (argDate.seconds) {
    date = new Date(argDate.seconds * 1000);
  }
  if (typeof argDate === "string") {
    date = new Date(argDate);
  }
  if (!date) {
    return "-";
  }
  if (!date.getTime) {
    return "-";
  }
  const hoursMoment = moment(date).hour();
  const minutesMoment = moment(date).minutes();
  let hour = hoursMoment < 10 ? "0" + hoursMoment : hoursMoment;
  let minutes = minutesMoment < 10 ? "0" + minutesMoment : minutesMoment;
  return hour + ":" + minutes;
};

export const searchOptionSelected = (options, idName, info) => {
  let indexSelected = -1;
  options.forEach((item, index) => {
    if (item.value[idName] === (info[idName] ? info[idName] : info)) {
      indexSelected = index;
    }
  });
  return indexSelected;
};

export const searchOptionSelectedName = (options, idName, info) => {
  let indexSelected = null;
  options.forEach((item, index) => {
    if (item.name === info.value) {
      indexSelected = index;
    }
  });
  return indexSelected;
};

export const searchOptionSelectedSimple = (options, info) => {
  let indexSelected = null;
  options.forEach((item, index) => {
    if (item.value === info) {
      indexSelected = index;
    }
  });
  return indexSelected;
};

export const transformToNumber = (input) => {
  const a = input.toString().replaceAll("$", "");
  const b = a.toString().replaceAll(".", "");
  return parseInt(b) ? parseInt(b) : 0;
};

export const defaultInfo = (data, form) => {
  if (data.defaultValue) {
    return formatInputText(data, data.defaultValue);
  } else {
    if (isNotNullOrUndefined(form)) {
      if (isNotNullOrUndefined(form[data.name])) {
        return formatInputText(data, form[data.name]);
      } else {
        return "";
      }
    }
  }
};

export const defaultInfoAutocomple = (data, form) => {
  const { name, options, idName } = data;
  if (form?.[name]) {
    const imputValue = options.find((item) => {
      if (item?.value?.[idName] === form?.[name]) {
        return true;
      }
      return false;
    });
    return imputValue?.name;
  } else {
    return "";
  }
};

export const transformToFloat = (input) => {
  const a = input.toString().replaceAll("$", "");
  const b = a.toString().replaceAll(",", ".");
  const number = parseFloat(b) ? parseFloat(b) : 0;
  return number;
};

export const manageWidth = (width) => {
  if (!isSmartphone()) {
    return width ? width : "100%";
  }
  return "100%";
};
