import React from "react";

export const massiveUploadConfig = [
  {
    auth: ["user"],
    routes: [
      {
        path: "/massive-upload-vehicles",
        component: React.lazy(() => import("./Page")),
      },
      {
        path: "/massive-upload-systems",
        component: React.lazy(() => import("./Page")),
      },
      {
        path: "/massive-upload-components",
        component: React.lazy(() => import("./Page")),
      },
      {
        path: "/massive-upload-spare-part",
        component: React.lazy(() => import("./Page")),
      },
      {
        path: "/massive-upload-stock-spare-part",
        component: React.lazy(() => import("./Page")),
      },
      {
        path: "/massive-upload-material",
        component: React.lazy(() => import("./Page")),
      },
      {
        path: "/massive-upload-stock-material",
        component: React.lazy(() => import("./Page")),
      },
      {
        path: "/massive-upload-work-order",
        component: React.lazy(() => import("./Page")),
      },
      {
        path: "/massive-upload-operator",
        component: React.lazy(() => import("./Page")),
      },
      {
        path: "/massive-upload-maintenance",
        component: React.lazy(() => import("./Page")),
      },
      {
        path: "/massive-upload-km-hr",
        component: React.lazy(() => import("./Page")),
      },
      {
        path: "/massive-upload-oil",
        component: React.lazy(() => import("./Page")),
      },
      {
        path: "/massive-upload-systems",
        component: React.lazy(() => import("./Page")),
      },
      {
        path: "/massive-upload-adjust-spare-part",
        component: React.lazy(() => import("./Page")),
      },
      {
        path: "/massive-upload-adjust-material",
        component: React.lazy(() => import("./Page")),
      },
      {
        path: "/massive-upload-material-suppliers",
        component: React.lazy(() => import("./Page")),
      },
      {
        path: "/massive-upload-suppliers",
        component: React.lazy(() => import("./Page")),
      },
      {
        path: "/massive-upload-transfer-spareParts",
        component: React.lazy(() => import("./Page")),
      },
      {
        path: "/massive-upload-transfer-materials",
        component: React.lazy(() => import("./Page")),
      },
    ],
  },
];
