import { put, call, select } from "@redux-saga/core/effects";
import {
  httpsPostFetchGoogleApi,
  httpsPostFilesFetch,
  responseBody,
} from "../services/https/post";

const getAuth = (state) => state.auth;

export const operationHttpsPostWithFiles = {
  name: "HTTPS_POST_WITH_FILES",
  reducer: {
    initialState: { creating: false, created: false, createFailed: false },
    cases: [
      {
        name: "SEND_HTTPS_POST_WITH_FILES_",
        newState: { creating: true, created: false, createFailed: false },
      },
      {
        name: "SUCCESS_HTTPS_POST_WITH_FILES_",
        newState: { creating: false, created: true, createFailed: false },
      },
      {
        name: "FAIL_HTTPS_POST_WITH_FILES_",
        newState: { creating: false, created: false, createFailed: true },
      },
    ],
  },
  action: {
    function: (name) => {
      return {
        sendHttpsPostWithFiles: (payload) => {
          return {
            type: "SEND_HTTPS_POST_WITH_FILES_" + name,
            payload: payload,
          };
        },
        successHttpsPostWithFiles: () => {
          return { type: "SUCCESS_HTTPS_POST_WITH_FILES_" + name };
        },
        failHttpsPostWithFiles: (payload) => {
          return {
            type: "FAIL_HTTPS_POST_WITH_FILES_" + name,
            payload: payload,
          };
        },
      };
    },
  },
  sagas: {
    sagasActionName: "SEND_HTTPS_POST_WITH_FILES_",
    function: (name) => {
      return function* sendPostWithFiles({ payload }) {
        try {
          yield put({ type: "LOAD_ALERT" });
          const { path, dataCreate, files, company } = payload;
          const auth = yield select(getAuth);
          if (
            auth.user.branchSelected &&
            auth.user.branchSelected !== "todos"
          ) {
            const dataWithBranch = {
              ...dataCreate,
              branch: auth.user.branchSelected.idBranch || auth.user.branch,
            };
            const response = yield call(
              httpsPostFilesFetch,
              path,
              dataWithBranch,
              files,
              company
            );
            if (response.status === 200) {
              yield put({ type: "SEND_SUCCESS" });
              yield put({ type: "SUCCESS_HTTPS_POST_WITH_FILES_" + name });
            } else {
              const responseBody2 = yield call(responseBody, response);
              yield put({
                type: "SEND_FAILED",
                payload: { dataLoad: { errorMessage: responseBody2.message } },
              });
            }
          } else {
            yield put({ type: "BRANCH_NO_SELECTED" });
          }
        } catch (e) {
          yield put({
            type: "SEND_FAILED",
            payload: { dataLoad: { errorMessage: "" } },
          });
          console.log("error https post" + name, e);
        }
      };
    },
  },
};
